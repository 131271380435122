import React from "react"
import { Link } from "gatsby"
import styles from "./styles.module.scss"

export default function PrivacyPolicy() {
    return (
        <section className={styles.privacy}>
            <div className={styles.privacy__container}>
                <h1 className={styles.privacy__title}>Sync.AI Services Privacy Policy</h1>
                <div className={styles.privacy__main}>
                    <p className={styles.c1} style={{ textAlign: "center" }}>
                        <span>Last updated by November 10, 2020</span>
                    </p>
                    <p className={styles.spacing} />
                    <p className={styles.spacing} />
                    <p className={styles.c1}>
                        If you are resident in California please click &nbsp;
                        <Link href={"/privacy-policy-ccpa"} strict>
                            here
                        </Link>
                        .
                    </p>

                    <p className={styles.c1}>
                        Sync.AI highly values the privacy of its users. Below you will find useful information about how
                        we collect, use and share your Personal Information, but before that – we would like to
                        highlight a few important points:
                    </p>
                    <ul className={styles.disc}>
                        <li>
                            <p className={styles.c1}>
                                Any Personal Information you create and/or upload to your user account on Sync.AI
                                Services would be used solely inside your user account environment, only for your
                                benefit and to enable the operation of the Sync.AI Services
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                Our Service plays a key role in enhancing connectivity and thwarting intrusive and
                                fraudulent calls, by introducing a variety of smart features that empower consumers to
                                make informed decisions and reduce the risks of the user community from being targeted
                                by malicious or misleading activities. These features include:
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                Any information you create or upload to your user account on Sync.AI Services would not
                                be shared with any third party, except where: (a) we receive your explicit consent to do
                                so, or (b) where we are legally required to disclose such information.
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                Our contact information is available at the bottom of this Policy. Please feel free to
                                reach out to us at any time..
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                We greatly respect your privacy, which is why we make every effort to provide a platform
                                that would live up to the highest of user privacy standards. Please read this Privacy
                                Policy carefully, so you can fully understand our practices and your rights in relation
                                to personal data.
                                <span className={styles.bold}>“Personal Data”</span> or{" "}
                                <span className={styles.bold}>“Personal Information”</span>
                                means any information that can be used, alone or together with other data, to uniquely
                                identify any living human being. Please note that this is a master privacy policy and
                                some of its provisions only apply to individuals in certain jurisdictions. For example,
                                the legal basis in the table below is only relevant for GDPR-protected individuals.
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                <span className={styles.bold}>Important note:</span> Nothing in this Privacy Policy is
                                intended to limit in any way your statutory right, including your rights to a remedy or
                                means of enforcement.
                            </p>
                        </li>
                    </ul>

                    <p className={styles.c1}>
                        In order to ensure transparency and give you more control over your Personal Information, this
                        privacy policy ( <span className={styles.bold}>“Privacy Policy”</span>) governs how we, Sync.Me
                        Technologies Ltd. (together, <span className={styles.bold}>“Sync.AI”</span>{" "}
                        <span className={styles.bold}>“we”</span>, <span className={styles.bold}>“our”</span> or{" "}
                        <span className={styles.bold}>“us”</span>) use, collect and store Personal Information we
                        collect or receive from or about you ( <span className={styles.bold}>“you”</span>).
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>1. WHAT INFORMATION WE COLLECT?</p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.underline}`}>
                        We collect the following types of information:
                    </p>

                    <ul className={`${styles.roman}`}>
                        <li>
                            <p className={`${styles.c1} ${styles.bold}`}>
                                <span className={`${styles.underline}`}>
                                    Calls.AI mobile application ("Calls.AI App").
                                </span>
                                &nbsp; Information provided by you when you create your user account information, log-in
                                and make use of the Calls.AI App:
                            </p>
                            <ul className={`${styles.letter} ${styles.nested}`}>
                                <li>
                                    <p className={styles.c1}>
                                        <span className={styles.bold}>Data Collected:</span> Log-in detail, this
                                        includes when you choose to sign in to, or create an account, using a third
                                        party platform login (such as Google or Microsoft); email address; full name;
                                        phone number; payment information (e.g., when you purchase a premium
                                        membership); the names, numbers and e-mail addresses contained in your device’s
                                        address book&nbsp; (<span className={styles.bold}>“Contact Information”</span>),
                                        for the purpose of providing the Caller ID; the phone number of the calls that
                                        you receive to provide you with the&nbsp;
                                        <span className={styles.bold}>"Missed Call Assistant"</span> and{" "}
                                        <span className={styles.bold}>"Business Card Feature"</span>; and any other
                                        Personal Information that you decide to provide or supply us with, including,
                                        without limitation, when you add notes of your cellphones. Also, we collect
                                        device information such as, your device’s type and model, mobile device ID, time
                                        zone, the device’s operating system, mobile carrier and internet service
                                        provider information, network connection type, mobile browsers installed on the
                                        device, your device’s approximate location, your device’s IP address, GCM
                                        Registration ID, and unique device identifiers assigned to your device (a number
                                        uniquely allocated to your device by your device manufacturer); and when using
                                        Calls.AI App, we collect app information, including, calls logs, app history and
                                        usage information, information regarding downloads and installations of mobile
                                        applications and any information regarding in-app events, internet domains
                                        visited in Calls.AI App, the duration of your visits and actions in these
                                        visits, please note that this information is anonymous and cannot identify you.
                                    </p>
                                    <p className={styles.c1}>
                                        Please note that any information you create or upload to your user account on
                                        Calls.AI App would be used solely inside your user account environment, only for
                                        your benefit and to enable the operation of the Calls.AI App.
                                    </p>
                                </li>
                                <li>
                                    <p className={styles.c1}>
                                        <span className={styles.bold}>Purposes:</span> (i) to create a user / log-in
                                        details (in order to use certain features of the Calls.AI App), (ii) provide you
                                        with the services, including specific features such as, the "Missed Call
                                        Assistant" and "Business Card Feature", in accordance with your to send the
                                        phone number that called you an SMS with indications to schedule a call with you
                                        ("Miss Call Assistant"); (iii) provide you with business productivity and
                                        intelligence services that organizes and provides efficient means to access and
                                        synchronize calls, notes, reminders, and contacts, which may have been
                                        previously dispersed over many different third party platforms; (iv) contact the
                                        users of the Calls.AI App for the purpose of providing them with technical
                                        assistance, support, handle requests and complaints and collect feedback in
                                        connection with performance of the Calls.AI App, (v) provide you with and to
                                        operate the Service, including for statistical and research purposes and
                                        creation of aggregated anonymous data, (vi) personalize the Calls.AI App and
                                        content provided to you, (vii) customize your experience with the Calls.AI, to
                                        suggest you with recommendations based on your preferences and to enable you to
                                        retrieve your Personal Information, (viii) develop, improve and customize the
                                        Calls.AI App, the experience of other users and the offering available through
                                        the Calls.AI App, and (ix) customize and improve our Calls.AI App’s accuracy and
                                        interface.
                                    </p>
                                </li>
                                <li>
                                    <p className={styles.c1}>
                                        <span className={styles.bold}>Legal basis (GDPR only, if applicable):</span>{" "}
                                        Depending on the context, processing is necessary for the performance of a
                                        contract to which the data subject is party or in order to take steps at the
                                        request of the data subject prior to entering into a contract, consent and/or
                                        legitimate interest (e.g. to allow you to create an account).
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className={`${styles.c1} ${styles.bold}`}>
                                <span className={`${styles.underline}`}>
                                    Calls.AI mobile application ("Calls.AI App").
                                </span>
                                &nbsp; Information provided by you when you create your user account information, log-in
                                and make use of the Calendar.AI App:
                            </p>
                            <ul className={`${styles.letter} ${styles.nested}`}>
                                <li>
                                    <p className={styles.c1}>
                                        <span className={styles.bold}>Data Collected:</span> : Log-in detail, this
                                        includes when you choose to sign in to, or create an account, using a third
                                        party platform login (such as Google or Microsoft); email address; full name;
                                        phone number; payment information (e.g., when you purchase a premium
                                        membership); the calendar data, meetings, appointments and/or other details
                                        contained in your calendar (
                                        <span className={styles.bold}>“Calendar Information”</span>), for the purpose of
                                        providing you with the services, such as, insights of the people with whom you
                                        are scheduling meetings in your calendar; and any other Personal Information
                                        that you decide to provide or supply us with, including, without limitation,
                                        when you add notes of your meetings. Also, we collect device information such
                                        as, your device’s type and model, mobile device ID, time zone, the device’s
                                        operating system, mobile carrier and internet service provider information,
                                        network connection type, mobile browsers installed on the device, your device’s
                                        approximate location, your device’s IP address, GCM Registration ID, and unique
                                        device identifiers assigned to your device (a number uniquely allocated to your
                                        device by your device manufacturer); and when using Calendar.AI App, we collect
                                        app information, including app history and usage information, information
                                        regarding downloads and installations of mobile applications and any information
                                        regarding in-app events, internet domains visited in Calendar.AI App, the
                                        duration of your visits and actions in these visits, please note that this
                                        information is anonymous and cannot identify you.
                                    </p>
                                    <p className={styles.c1}>
                                        Please note that any information you create or upload to your user account on
                                        Calendar.AI App would be used solely inside your user account environment, only
                                        for your benefit and to enable the operation of the Calendar.AI App.
                                    </p>
                                </li>
                                <li>
                                    <p className={styles.c1}>
                                        <span className={styles.bold}>Purposes:</span> (i) to create a user / log-in
                                        details (in order to use certain features of the Calendar.AI App), (ii) provide
                                        you with the services, including the "view insight feature"; (iii) provide you
                                        with business productivity and intelligence services that organizes and provides
                                        efficient means to access and synchronize meetings, notes, reminders, and
                                        contacts, which may have been previously dispersed over many different third
                                        party platforms; (iv) contact the users of the Calendar.AI App for the purpose
                                        of providing them with technical assistance, support, handle requests and
                                        complaints and collect feedback in connection with performance of the
                                        Calendar.AI, (v) provide you with and to operate the Calendar.AI, including for
                                        statistical and research purposes and creation of aggregated anonymous data,
                                        (vi) personalize the Calendar.AI App and content provided to you, (vii)
                                        customize your experience with the Calendar.AI App, to suggest you with
                                        recommendations based on your preferences and to enable you to retrieve your
                                        Personal Information, (viii) develop, improve and customize the Calendar.AI App,
                                        the experience of other users and the offering available through the Calendar.AI
                                        App, and (ix) customize and improve our Calendar.AI App’s accuracy and
                                        interface.
                                    </p>
                                </li>
                                <li>
                                    <p className={styles.c1}>
                                        <span className={styles.bold}>Legal basis (GDPR only, if applicable):</span>{" "}
                                        Depending on the context, processing is necessary for the performance of a
                                        contract to which the data subject is party or in order to take steps at the
                                        request of the data subject prior to entering into a contract, consent and/or
                                        legitimate interest (e.g. to allow you to create an account).
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className={`${styles.c1} ${styles.bold}`}>
                                Personal information you give us when you contact us through the website:&nbsp;
                                <Link href={"/"}>http://sync.ai</Link> ("Site")
                            </p>
                            <ul className={`${styles.letter} ${styles.nested}`}>
                                <li>
                                    <p className={styles.c1}>
                                        <span className={styles.bold}>Data collected and for which purposes:</span>
                                        <ol className={`${styles.roman} ${styles.nested}`}>
                                            <li>
                                                <p className={styles.c1}>
                                                    When voluntarily filling out the ‘contact us’ form in our Site, you
                                                    may be asked to provide your name and email address, and you may
                                                    voluntarily provide any other information you deem relevant. Also,
                                                    we will be able to reply to your online queries in connection with
                                                    performance of the services (e.g., support).
                                                </p>
                                                <p className={styles.c1}>
                                                    Legal basis (GDPR only, if applicable): Processing is necessary for
                                                    the performance of a contract to which the data subject is party or
                                                    in order to take steps at the request of the data subject prior to
                                                    entering into a contract and/or legitimate interest (e.g. to answer
                                                    your queries).
                                                </p>
                                            </li>
                                            <li>
                                                <p className={styles.c1}>
                                                    We also collect cookies, analytics and other tracking technologies
                                                    details to improve your experience with our Site. For more
                                                    information, please read our cookie policy: [
                                                    <a href="/cookies-policy">
                                                        sync.ai/cookies-policy
                                                    </a>
                                                    ]
                                                </p>
                                                <p className={styles.c1}>
                                                    Legal basis (GDPR only, if applicable): Consent and/or legitimate
                                                    interest (e.g. essential cookies).
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                We also use your information if we have a good faith belief that disclosure of such
                                information is helpful or reasonably necessary to: (i) comply with any applicable law,
                                regulation, legal process or governmental request; (ii) enforce our policies (including
                                our terms and Privacy Policy), including investigations of potential violations thereof;
                                (iii) investigate, detect, prevent, or take action regarding illegal activities or other
                                wrongdoing, suspected fraud or security issues; (iv) to establish or exercise our rights
                                to defend against legal claims; (v) prevent harm to the rights, property or safety of
                                Company, our users, yourself or any third party, or (vi) in certain cases, we may or
                                will anonymize or de-identify your Personal Information and further use it for internal
                                and external purposes, including, without limitation, to improve the services and for
                                research purposes, we may be shared with third parties (including ad networks,
                                advertising companies and service providers) in any of the above circumstances, as well
                                as for the purpose of developing or delivering tailored or targeted advertising (whether
                                via our App or Site), for the purpose of improving our Service, conducting business
                                analysis or other business purposes. This information may be combined with information
                                we collect from other sources, to the extent that the information remains anonymous.
                                “Anonymous Information” means information which does not enable identification of an
                                individual user, such as aggregated information about the use of our services. We may
                                use Anonymous Information and/or disclose it to third parties without restrictions (for
                                example, in order to improve our services and enhance your experience with them).
                            </p>
                        </li>
                    </ul>
                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>
                        2. WITH WHOM DO WE SHARE YOUR INFORMATION?
                    </p>

                    <p className={styles.c1}>
                        <span className={`${styles.bold} ${styles.underline}`}>We share information:</span>
                    </p>
                    <ul className={styles.roman}>
                        <li>
                            <p className={styles.c1}>
                                With the companies of our group. We disclose information about you to other companies of
                                the group. They may combine your Personal Information with other details they hold about
                                you. Unless they provide you with their own privacy policy, they may use your Personal
                                Information for the purposes explained in this Policy.
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                With our service providers and/our business partners with whom we jointly offer products
                                or services. We share your Personal Information with our service providers and
                                subcontractors who assist us with our business operations (as listed above), such as for
                                the purpose of storing the information, performing research, technical diagnostics,
                                identity verification, analytics or statistical purposes, or other business operations.
                                With our subsidiaries or in connection with a sale of our business – if a third party
                                acquires some or all of our business or assets, or in the event that we are acquired by
                                or merged with a third party entity, or in the event of bankruptcy or a comparable
                                event, we reserve the right to transfer or assign information in connection with the
                                foregoing events. In the event of the above, our affiliated companies or acquiring
                                company will assume the rights and obligations as described in this Privacy Policy. When
                                we are legally obligated to do so.
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                To the extent necessary, with regulators, courts or competent authorities, to comply
                                with applicable laws, regulations and rules (including, without limitation, federal,
                                state or local laws), and requests of law enforcement, regulatory and other governmental
                                agencies or if required to do so by court order;
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                If, in the future, we sell or transfer, or we consider selling or transferring, some or
                                all of our business, shares or assets to a third party, we will disclose your Personal
                                Data to such third party (whether actual or potential) in connection with the foregoing
                                events;
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                In the event that we are acquired by, or merged with, a third party entity, or in the
                                event of bankruptcy or a comparable event, we reserve the right to transfer, disclose or
                                assign your Personal Information in connection with the foregoing events, including, in
                                connection with, or during negotiations of, any merger, sale of company assets,
                                consolidation or restructuring, financing, or acquisition of all or a portion of our
                                business by or to another company; and/or
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                Where you have provided your consent to us sharing or transferring your Personal
                                Information (e.g., where you provide us with marketing consents or opt-in to optional
                                additional services or functionality).
                            </p>
                        </li>
                    </ul>
                    <p className={styles.c1}>
                        If you want to receive the list of the current recipients of your Personal Data, please make
                        your request by contacting us to&nbsp;
                        <Link href={"mailto:support@sync.ai"}>support@sync.ai</Link>.
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>3. INTERNATIONAL TRANSFERS</p>

                    <p className={styles.c1}>
                        We may transfer the information we collect about you to countries other than the country where
                        we originally collected it for the purposes of storage and processing of information and
                        operating our services. Those countries may not have the same information protection laws as
                        your country. However, when we transfer your information to other countries, we will protect
                        that information as described in this Privacy Policy and take steps, where necessary, to ensure
                        that international transfers comply with applicable laws.
                    </p>

                    <p className={styles.c1}>
                        We do commercially reasonable efforts to protect your Personal Information in accordance with
                        our Privacy Policy, through contractual means (such as, by using the standard contractual
                        clauses approved by the European Commission for data transfer,) or other means (such as ensuring
                        that the European Commission decisions determined that such jurisdictions offer adequate level
                        of protection,).
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>4. YOUR RIGHTS</p>

                    <p className={styles.c1}>
                        If you reside in the EU or in a jurisdiction that affords you with the below rights, you may
                        contact us through the contact information available below, and request:
                    </p>
                    <ul>
                        <li>
                            <p className={styles.c1}>
                                To access any Personal Information relating to you, including receiving supplementary
                                information regarding its use;
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                To change or update any Personal Information relating to you (for example, if you
                                believe that your personal information is incorrect, you may ask to have it corrected or
                                deleted). Note that you can also request that we correct errors with regard to your
                                Personal Information (except in cases where the information is required to be kept in
                                its original format under any applicable laws and regulations);
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                That we will restrict or cease any further use of your Personal Information;
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                That we will provide the Personal Information you volunteered to us in a
                                machine-readable format.
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                To withdraw your consent for any processing activities that relied on your consent.
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                Your email should include adequate details of your request and you may be requested to
                                provide additional details in order to authenticate your identity and accommodate your
                                requests.
                            </p>
                        </li>
                    </ul>
                    <p className={styles.c1}>
                        Please note that these rights are not absolute and requests are subject to any applicable legal
                        requirements. We may also rectify, replenish or remove incomplete or inaccurate information, at
                        any time in accordance with our internal policies.
                    </p>
                    <p className={styles.c1}>
                        <span className={`${styles.underline}`}>Deleting your account:</span> Should you ever decide to
                        delete your account, you may do so by emailing &nbsp;
                        <Link href={"mailto:support@sync.ai"}>support@sync.ai</Link>. If you terminate your account, any
                        association between your account and Personal Information we store will no longer be accessible
                        through your account. However, given the nature of sharing on certain services, any public
                        activity on your account prior to deletion will remain stored on our servers and will remain
                        accessible to the public.
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>5. RETENTION OF PERSONAL INFORMATION.</p>

                    <p className={styles.c1}>
                        Your information will be retained as long as your account on Sync.AI services remains active. If
                        your account is terminated we will delete any information you submitted through your account,
                        except where retaining such information is necessary for our legitimate interests, such as for
                        fraud prevention and record keeping.
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>6. CHILDREN PRIVACY.</p>

                    <p className={styles.c1}>
                        Our Service is not intended for minors below the age of sixteen (16). We do not knowingly
                        collect Personal Information from children under the age of sixteen (16) and does not wish to do
                        so. We reserve the right to request proof of age at any stage so that we can verify that minors
                        under the age of sixteen (16) are not using the Services. In the event that it comes to our
                        knowledge that a person under the age of sixteen (16) is using the Services, we may prohibit and
                        block such User from using the Services and will make all efforts to promptly delete any
                        Personal Information with respect to such User. If you believe that we might have any such
                        information, please contact us at&nbsp;
                        <Link href={"mailto:support@sync.ai"}>support@sync.ai</Link>.
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>7. SECURITY.</p>

                    <p className={styles.c1}>
                        We have implemented appropriate technical, organizational and security measures designed to
                        protect your Personal Information. However, please note that we cannot guarantee that the
                        information will not be compromised as a result of unauthorized penetration to our servers. As
                        the security of information depends in part on the security of the computer, device or network
                        you use to communicate with us and the security you use to protect your user IDs and passwords,
                        please make sure to take appropriate measures to protect this information.
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>
                        8. INTERACTION WITH THIRD PARTY PRODUCTS.
                    </p>

                    <p className={styles.c1}>
                        “Third Party Platforms” are software that integrate with our services and that you have
                        authorized to connect with Sync.AI Services. This may include email, calendar, messaging, file
                        storage, phone services, note taking, reminders, social networking, and other cloud services and
                        other similar platforms from which you authorize us to import, search, and access content. We
                        enable you to interact with Third Party Platforms, however, we are not responsible for the
                        privacy practices or the content of such Third Party Platforms. Please be aware that Third Party
                        Platforms can collect Personal Information from you. Accordingly, we encourage you to read the
                        terms and conditions and privacy policies of each Third Party Platforms.
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>9. LOG FILES.</p>

                    <p className={styles.c1}>
                        We use log files. The information inside the log files. We use such information to analyze
                        trends, administer the Website, track users’ movement around the Website, and gather demographic
                        information.
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>10. ANALYTIC TOOLS.</p>
                    <ul>
                        <li>
                            <p className={styles.c1}>
                                <span className={styles.bold}>Google Analytics.</span>&nbsp; The Website uses a tool
                                called “Google Analytics” to collect information about use of the Website. Google
                                Analytics collects information such as how often users visit this Website, what pages
                                they visit when they do so, and what other websites they used prior to coming to this
                                Website. We use the information we get from Google Analytics to maintain and improve the
                                Website and our products. We do not combine the information collected through the use of
                                Google Analytics with Personal Information we collect. Google’s ability to use and share
                                information collected by Google Analytics about your visits to this Website is
                                restricted by the Google Analytics Terms of Service, available at&nbsp;
                                <a href="https://marketingplatform.google.com/about/analytics/terms/us/">
                                    https://marketingplatform.google.com/about/analytics/terms/us/
                                </a>
                                , and the Google Privacy Policy, available at{" "}
                                <a href="http://www.google.com/policies/privacy/">
                                    http://www.google.com/policies/privacy/
                                </a>
                                . You may learn more about how Google collects and processes data specifically in
                                connection with Google Analytics at{" "}
                                <a href="http://www.google.com/policies/privacy/partners/">
                                    http://www.google.com/policies/privacy/partners/
                                </a>
                                . You may prevent your data from being used by Google Analytics by downloading and
                                installing the Google Analytics Opt-out Browser Add-on, available at
                                <a href="https://tools.google.com/dlpage/gaoptout/">
                                    https://tools.google.com/dlpage/gaoptout/
                                </a>
                                .
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                <span className={styles.bold}>Firebase Analytics.</span>&nbsp; We also use{" "}
                                <span className={styles.bold}>“Google Analytics for Firebase”</span> By enabling this
                                tool, we enable the collection of data about App Users, including via identifiers for
                                mobile devices (including Android Advertising ID and Advertising Identifier for iOS),
                                cookies and similar technologies. We use the information we get from Google Analytics
                                for Firebase to maintain and improve our App(s). We do not facilitate the merging of
                                personally-identifiable information with non-personally identifiable information unless
                                we have robust notice of, and your prior affirmative (i.e., opt-in) consent to, that
                                merger. Finally, please note that Google Analytics for Firebase’s terms (available at{" "}
                                <a href="https://firebase.google.com/terms/">https://firebase.google.com/terms/</a>)
                                shall also apply. .
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                <span className={styles.bold}>AppsFlyer.</span>&nbsp; We use a tool called “AppsFlyer”,
                                a mobile attribution and marketing analytics platform to understand the use of our
                                services. AppsFlyer is exposed to the following data: (i) unique identifiers and
                                technical data, such as IP address, User agent, IDFA (Identifier For Advertisers) or
                                Android ID (in Android devices); and (ii) technical data regarding your operating
                                system, device attributes and settings, applications, advertising opt-out signals,
                                Google Advertiser ID, in-app events, device motion parameters and carrier. The use of
                                this data allows us to analyze our campaigns and performance, as well as your habits and
                                characteristics. For example, the data AppsFlyer receives includes downloads,
                                impressions, clicks and installations of their mobile applications, mobile device use
                                and data regarding in-app events. AppsFlyer’s terms of use (available at
                                <a href="https://www.appsflyer.com/terms-of-use/">
                                    https://www.appsflyer.com/terms-of-use/
                                </a>
                                ) and privacy policy (available at
                                <a href="https://www.appsflyer.com/privacy-policy/">
                                    https://www.appsflyer.com/privacy-policy/
                                </a>
                                ) also apply to the use of AppsFlyer.
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                <span className={styles.bold}>Hotjar.</span>&nbsp; The Website uses Hotjar in order to
                                better understand our users’ needs and to optimize this service and experience. Hotjar
                                is a technology service that helps us better understand our users experience (e.g. how
                                much time they spend on which pages, which links they choose to click, what users do and
                                don’t like, etc.) and this enables us to build and maintain our service with user
                                feedback. Hotjar uses cookies and other technologies to collect data on our users’
                                behavior and their devices (in particular device's IP address (captured and stored only
                                in anonymized form), device screen size, device type (unique device identifiers),
                                browser information, geographic location (country only), preferred language used to
                                display our Website). Hotjar stores this information in a pseudonymized user profile.
                                Neither Hotjar nor we will ever use this information to identify individual users or to
                                match it with further data on an individual user. For further details, please see
                                Hotjar’s privacy policy at{" "}
                                <a href="https://www.hotjar.com/legal/policies/privacy">
                                    https://www.hotjar.com/legal/policies/privacy
                                </a>
                                . You can opt-out to the creation of a user profile, Hotjar’s storing of data about your
                                usage of our Website and Hotjar’s use of tracking cookies on other websites on this link
                                <a href="https://www.hotjar.com/legal/compliance/opt-out">
                                    https://www.hotjar.com/legal/compliance/opt-out
                                </a>
                                .
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                <span className={styles.bold}>Advertising Partners.</span>&nbsp; Through our services,
                                we allow third party advertising partners to set technologies and other tracking tools
                                to collect information regarding your activities and your device (e.g., your IP address,
                                mobile identifiers, page(s) visited, location, time of day). We also combine and share
                                such information and other information (such as demographic information and past
                                purchase history) with third party advertising partners. These advertising partners will
                                use this information (and similar information collected from other websites) for
                                purposes of delivering targeted advertisements to you when you visit third party
                                websites within their networks. This practice is commonly referred to as “interest-based
                                advertising” or “online behavioral advertising. We allow access to other data collected
                                by the services to share information that may be useful, relevant, valuable or otherwise
                                of interest to you. If you prefer not to share your Personal Information with third
                                party advertising partners, you may let us know.
                            </p>
                        </li>
                        <li>
                            <p className={styles.c1}>
                                <span className={styles.bold}>Amplitude.</span>&nbsp; We reserve the right to remove or
                                add new analytic tools.
                            </p>
                        </li>
                    </ul>


                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>11. GOOGLE PRIVACY POLICY AND YOUTUBE API.</p>
                    <p className={styles.c1}>
                         Please note that the Sync.AI Services may use the YouTube API to provide
                                you with Youtube videos in the People & Company Insights feature.
                                By using this integration you are agreeing to be bound by the YouTube Terms of Service
                                (<Link href={"https://www.youtube.com/t/terms"}>https://www.youtube.com/t/terms</Link>),
                                Privacy Policy (<Link href={"http://www.google.com/policies/privacy"}>http://www.google.com/policies/privacy</Link>),
                                and API Services Terms of Service
                                (<Link href={"https://developers.google.com/youtube/terms/api-services-terms-of-service"}>https://developers.google.com/youtube/terms/api-services-terms-of-service</Link>).
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>12. CHANGES TO OUR PRIVACY POLICY.</p>

                    <p className={styles.c1}>
                        We reserve the right, at our discretion, to revise or update this Privacy Policy at any time. We
                        will provide notice of substantial changes of this Privacy Policy on Sync.AI Services. To the
                        maximum extent permitted by law, any material change will be effective five (7) days following
                        posting the revised Privacy Policy (available from Sync.AI Services) and/or we will send you an
                        e-mail regarding such changes to the e-mail address that you volunteered, or by other means.
                        Otherwise, all other changes to this Privacy Notice are effective as of the stated “Last
                        Updated” date, and your continued use of the Services after the Last Revised date will
                        constitute acceptance of, and agreement to be bound by, those changes. Please make sure to
                        review our Privacy Policy periodically.
                    </p>

                    <p className={`${styles.c1} ${styles.bold} ${styles.c2}`}>13. CONTACT US.</p>

                    <p className={styles.c1}>
                        If you have any other questions or queries about our Privacy Policy, please contact us by email
                        in the following address: <a href="mailto:support@sync.ai">support@sync.ai</a>.
                    </p>
                </div>
            </div>
        </section>
    )
}
